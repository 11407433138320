@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

@mixin box-shadow($shadowinfo) {
  -webkit-box-shadow: $shadowinfo;
  -moz-box-shadow: $shadowinfo;
  -ms-box-shadow: $shadowinfo;
  box-shadow: $shadowinfo;
}

@mixin transform($transform) {
  -moz-transform: $transform;
  -ms-transform: $transform;
  -o-transform: $transform;
  -webkit-transform: $transform;
  transform: $transform;
}

@mixin transition($transition) {
  -moz-transition: $transition;
  -o-transition: $transition;
  -webkit-transition: $transition;
  transition: $transition;
}

@mixin three-dots-overflow() {
  white-space: nowrap;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
}

@mixin navbar-brand() {
  white-space: nowrap;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

@mixin navbar-link-color($textcolor, $navbarcolor, $opacity) {

  .navbar-brand,
  .navbar-brand:hover,
  .navbar-brand:active,
  .navbar-brand:focus {
    color: $textcolor;
  }

  .nav>li>a:hover,
  .nav>li>a:focus,
  .nav .open>a,
  .nav .open>a:hover,
  .nav .open>a:focus {
    background-color: transparentize($navbarcolor, $opacity);
  }

  .nav>li>a {
    color: $textcolor;
  }

  .bars {
    float: left;
    padding: 10px 20px;
    font-size: 22px;
    color: $textcolor;
    margin-right: 10px;
    margin-left: -10px;
    margin-top: 4px;
  }

  .bars:hover {
    background-color: rgba(0, 0, 0, 0.08);
  }
}